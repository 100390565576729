/** @jsx jsx */
import ThemeProvider from 'providers/ThemeProvider'
import {Fragment} from 'react'
import {HelmetProvider} from 'react-helmet-async'
import TableauEmbeddedViz from 'screens/TableauEmbeddedViz'
import {jsx} from 'theme-ui'
import {Auth0Provider} from 'providers/AuthProvider'
import history from './utils/history'

const {REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID} = process.env

const onRedirectCallback = (
  appState:
    | {
        targetUrl: string
      }
    | undefined,
) => {
  history.push((appState && appState.targetUrl) || window.location.pathname)
}

const App = () => {
  if (!REACT_APP_AUTH0_DOMAIN || !REACT_APP_AUTH0_CLIENT_ID) {
    return (
      <div>
        <h1>Error</h1>
        <p>
          The Auth0 environment variables should be set, please contact the
          admin
        </p>
      </div>
    )
  }
  return (
    <HelmetProvider>
      <ThemeProvider>
        <Auth0Provider
          domain={REACT_APP_AUTH0_DOMAIN}
          client_id={REACT_APP_AUTH0_CLIENT_ID}
          redirect_uri={window.location.origin}
          onRedirectCallback={onRedirectCallback}
        >
          <Fragment>
            <TableauEmbeddedViz />
          </Fragment>
        </Auth0Provider>
      </ThemeProvider>
    </HelmetProvider>
  )
}

export default App
