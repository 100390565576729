import 'normalize.css'
import React from 'react'
import theme from 'theme'
import {ThemeProvider} from 'theme-ui'

const _ThemeProvider: React.FC<{}> = ({children}) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default _ThemeProvider
