// src/react-auth0-spa.js
import React, {useState, useEffect, useContext} from 'react'
import createAuth0Client from '@auth0/auth0-spa-js'
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client'

interface RedirectCallback {
  (appState?: any): void
}
const DEFAULT_REDIRECT_CALLBACK: RedirectCallback = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

interface Auth0ProviderProps {
  onRedirectCallback: RedirectCallback
  domain: string
  client_id: string
  redirect_uri?: string
}

const emptyFunction = () => {}

export const Auth0Context = React.createContext<{
  isAuthenticated: boolean
  user: any
  loading: boolean
  popupOpen: boolean
  loginWithPopup: (params?: {}) => Promise<void>
  handleRedirectCallback: RedirectCallback
  getIdTokenClaims: () => void
  loginWithRedirect: (params?: {}) => void
  getTokenSilently: () => void
  getTokenWithPopup: () => void
  logout: () => void
}>({
  isAuthenticated: false,
  user: {},
  loading: false,
  popupOpen: false,
  loginWithPopup: () => new Promise(emptyFunction),
  handleRedirectCallback: DEFAULT_REDIRECT_CALLBACK,
  getIdTokenClaims: emptyFunction,
  loginWithRedirect: emptyFunction,
  getTokenSilently: emptyFunction,
  getTokenWithPopup: emptyFunction,
  logout: emptyFunction,
})
export const useAuth0 = () => useContext(Auth0Context)
export const Auth0Provider: React.FC<Auth0ProviderProps> = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [auth0Client, setAuth0] = useState<Auth0Client>()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions)
      setAuth0(auth0FromHook)

      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        const {appState} = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated()

      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser()
        setUser(user)
      }

      setLoading(false)
    }
    initAuth0()
    // eslint-disable-next-line
  }, [])

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    if (!auth0Client) return
    try {
      await auth0Client.loginWithPopup(params)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    const user = await auth0Client.getUser()
    setUser(user)
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    if (!auth0Client) return
    await auth0Client.handleRedirectCallback()
    const user = await auth0Client.getUser()
    setLoading(false)
    setIsAuthenticated(true)
    setUser(user)
  }
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client?.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client?.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client?.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client?.getTokenWithPopup(...p),
        logout: (...p) => auth0Client?.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}
