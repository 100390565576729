export default {
  fonts: {
    body:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    // heading: '"Avenir Next", sans-serif',
  },
  colors: {
    text: '#000',
    background: '#fff',
    primary: '#33e',
  },
}
