/** @jsx jsx */
import {jsx} from 'theme-ui'
import React, {Fragment} from 'react'
import {Helmet} from 'react-helmet-async'
import Layout from 'components/Layout'
import {useAuth0} from 'providers/AuthProvider'

const TableauEmbeddedViz: React.FC = () => {
  const {isAuthenticated} = useAuth0()
  return (
    <Layout>
      {isAuthenticated ? (
        <Fragment>
          <Helmet>
            <script
              id="tableauScript"
              type="text/javascript"
              src="https://10ax.online.tableau.com/javascripts/api/viz_v1.js"
            ></script>
          </Helmet>
          <div
            dangerouslySetInnerHTML={{
              __html: `<div class='tableauPlaceholder' style='width: 1792px; height: 938px;'><object class='tableauViz' width='1792' height='938' style='display:none;'><param name='host_url' value='https%3A%2F%2F10ax.online.tableau.com%2F' /> <param name='embed_code_version' value='3' /> <param name='site_root' value='&#47;t&#47;marketingadvancedev950892' /><param name='name' value='Regional&#47;Obesity' /><param name='tabs' value='yes' /><param name='toolbar' value='yes' /><param name='showAppBanner' value='false' /></object></div>`,
            }}
          ></div>
        </Fragment>
      ) : (
        <div>You must login to see the content</div>
      )}
    </Layout>
  )
}

export default TableauEmbeddedViz
