/** @jsx jsx */
import {jsx} from 'theme-ui'
import {useAuth0} from '../providers/AuthProvider'
import {Fragment} from 'react'

const NavBar = () => {
  const {isAuthenticated, loginWithRedirect, logout, user = {}} = useAuth0()
  return (
    <div
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 70,
        px: 20,
        backgroundColor: '#eee',
        overflow: 'hidden',
      }}
    >
      <h1>Tapcast</h1>
      <div
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {!isAuthenticated && (
          <Fragment>
            <button onClick={() => loginWithRedirect({})}>Log in</button>
          </Fragment>
        )}
        {isAuthenticated && (
          <Fragment>
            <img
              src={user.picture}
              alt=""
              sx={{
                height: '100%',
              }}
            />
            <div sx={{mx: 10}}>{user.name}</div>

            <button onClick={logout}>Log out</button>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default NavBar
